<template>
  <b-modal
    @shown="updateItem"
    :title="`新增${nowPlace == 3 || nowPlace == 4 ? '違約' : ''}合約書`"
    id="addNewContractFile"
    size="lg"
  >
    <b-container fluid>
      <main class="main-grid-dtc">
        <b-input-group prepend="合約名稱" style="grid-column: 1/-1;">
          <b-input v-model="subject"></b-input>
        </b-input-group>
        <b-input-group prepend="上傳合約" style="grid-column: 1/-1;">
          <div class="custom-file-upload-out">
            <label for="file-upload" class="custom-file-upload">
              <span style="color: rgb(105 36 136);">
                <i class="fas fa-cloud-upload-alt fa-lg"></i>
              </span>
              <div>Upload File</div>
              <input
                id="file-upload"
                type="file"
                @change="fileChange"
                accept=".pdf"
                ref="inputFile"
              />
            </label>
          </div>
          <div class="mt-2 ml-2">
            <b-button
              variant="danger"
              class="float-right mr-3"
              @click="removeFile"
              >移除</b-button
            >
          </div>

          <div style="padding-top:13px;padding-left:8px">{{ fileName }}</div>
        </b-input-group>
      </main>
    </b-container>

    <template v-slot:modal-footer>
      <div class="w-100">
        <b-button
          variant="warning"
          class="float-right mr-3"
          @click="$bvModal.hide('addNewContractFile')"
          >取消</b-button
        >
        <b-button
          variant="primary"
          class="float-right mr-3"
          @click="uploadNewFile"
          >確定</b-button
        >
      </div>
    </template>
  </b-modal>
</template>

<script>
import { store } from "@/store/global.js";
export default {
  name: "DownloadAreaAddNew",
  data() {
    return {
      subject: "",
      file: null,
      item: {},
      fileName: "",
      variants: [
        "warning",
        "dark",
        "light",
        "warning",
        "danger",
        "info",
        "light",
        "dark",
      ],
      personData: {},
    };
  },
  computed: {
    nowPlace() {
      return store.nowIndex;
    },
  },
  methods: {
    updateItem() {
      this.subject = "";
      this.fileName = "";
      this.file = null;
      this.$refs.inputFile.value = null;
      this.personData = { ...store.editItem };
    },
    removeFile() {
      this.$refs.inputFile.value = null;
      this.fileName = "";
      this.file = null;
    },
    fileChange(e) {
      this.fileName = e.target.files[0].name;
      this.file = e.target.files[0];
    },
    async uploadNewFile() {
      let form = new FormData();
      form.append("UploadFile", this.file);
      form.append("Remark", "" + this.subject);
      form.append("Identifier", "" + this.personData.id);

      let api = "";
      if (this.nowPlace == 21 || this.nowPlace == 31 || this.nowPlace == 2 || this.nowPlace == 41 || this.nowPlace == 51) {
        api = "StudentInfo/PostContract";
      } else if (this.nowPlace == 3 || this.nowPlace == 4) {
        api = "StudentInfo/PostRepaymentDoc";
      }

      try {
        await window.axios.post(api, form);
        this.$bvToast.toast(`新增成功!!`, {
          title: `${
            this.nowPlace == 3 || this.nowPlace == 4 ? "違約" : ""
          }合約管理`,
          autoHideDelay: 5000,
          variant: "success",
          toaster: "b-toaster-bottom-full",
        });
        this.$bvModal.hide("addNewContractFile");
      } catch (e) {
        
        this.$bvToast.toast("新增失敗!! " + e, {
          title: `${
            this.nowPlace == 3 || this.nowPlace == 4 ? "違約" : ""
          }合約管理`,
          autoHideDelay: 5000,
          variant: "danger",
          toaster: "b-toaster-bottom-full",
        });
      }
    },
  },
  mounted() {},
  watch: {},
};
</script>

<style scoped lang="scss">
p {
  font-size: 2em;
  text-align: center;
}
.input-group-text {
  width: 150px;
  max-width: 150px;
  min-width: 150px;
}
.code-dtc {
  display: grid;
  grid-template-columns: 60px minmax(400px, 525px);
}
.input-group {
  margin-bottom: 10px;
}
.main-grid-dtc {
  display: grid;
  grid-template-columns: 350px 1fr;
}
.main-grid3-dtc {
  display: grid;
  grid-template-columns: 350px 350px 1fr;
}

.custom-file-upload-out {
  border: 1px solid #ccc;
  padding: 6px 12px;
  .custom-file-upload {
    display: inline-block;
    padding-top: 6px;
    cursor: pointer;
    display: grid;
    grid-template-columns: max-content max-content;
    grid-gap: 10px;

    input[type="file"] {
      position: absolute;
      width: 1px;
      height: 1px;
      padding: 0;
      margin: -1px;
      overflow: hidden;
      clip: rect(0, 0, 0, 0);
      border: 0;
    }
  }
}
::v-deep #addNewContractFile > .modal-dialog > .modal-content > .modal-header {
  background: #7e2ca3;
  color: #ffffff;
  .close {
    color: #ffffff;
  }
}
.form-control[readonly] {
  background-color: #ffffff;
}
::v-deep .modal-content {
  margin-top: 110px;
}
</style>
