<template>
  <div style="height: 95.4%">
    <ContractViewAdd></ContractViewAdd>
    <div class="title">
      <div class="info">
        <span class="ml-2"
          >查看{{ nowPlace == 3 || nowPlace == 4 ? "違約" : "" }}合約 - </span
        >編號:<span class="mr-2">{{ personData.code }}</span
        >姓名: <span class="mr-2">{{ personData.name }}</span
        >身分證字號: <span class="mr-3">{{ personData.id }}</span>
      </div>
      <b-button
        size="sm"
        variant="primary"
        class="mr-2 d-print-none dtc-print-btn2"
        @click="showContractList"
        >合約總覽</b-button
      >
      <b-button
        size="sm"
        variant="success"
        class="mr-2 d-print-none dtc-print-btn2"
        @click="addNewContract"
        >新增</b-button
      >
      <b-button
        size="sm"
        variant="warning"
        class="mr-2 d-print-none dtc-print-btn2"
        @click="backToLastPage"
        >返回</b-button
      >
      <span style="color:#eeebeb" v-show="!contractList.length"
        >尚無{{ nowPlace == 3 || nowPlace == 4 ? "違約" : "" }}合約!</span
      >
    </div>
    <!--vue-pdf-app
      :pdf="pdfPath"
      style="position: relative;overflow: hidden;"
      :config="config"
    >
      <template #footer="{ toggleTheme }">
        <div class="footer" style="padding-bottom:10px">
          <span class="pl-2">{{ pdfTitle }}</span>
          <button
            @click="toggleTheme"
            class="vue-pdf-app-icon theme-toggle"
            type="button"
            title="Toggle theme"
          ></button>
        </div>
      </template>
    </vue-pdf-app-->
    <b-modal id="modalContract" title="合約總覽">
      <b-list-group>
        <main
          v-for="(item, i) in contractList"
          :key="`modalContract${i}`"
          class="dtc-grid-contract"
        >
        <b-list-group-item class="contract" style="display: flex; justify-content: space-between;" @click="changeContract(item)">
          <span>{{ item.title }}</span>
          <b-button variant="primary" @click="downloadPdf(item.url)">下載PDF</b-button>
        </b-list-group-item>

          <div class="delete">
            <b-button
              variant="danger"
              class="float-right mr-1"
              @click="deleteContract(item)"
              >刪除</b-button
            >
          </div>
        </main>
      </b-list-group>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-button
            variant="primary"
            class="float-right mr-1"
            @click="$bvModal.hide('modalContract')"
            >確定</b-button
          >
        </div>
      </template>
    </b-modal>
    <div>
    <!-- 其他的模板内容 -->

    <!-- 添加一个按钮用于下载PDF文件 -->
  </div>
  </div>
</template>

<script>
import { store } from "@/store/global.js";
//import VuePdfApp from "vue-pdf-app";
import "vue-pdf-app/dist/icons/main.css";
import ContractViewAdd from "./ContractViewAdd";
import { domainObject } from "@/constant.js";

export default {
  name: "ThemeToggling",
  data() {
    return {
      pdfPath: "",
      pdfTitle: "請上傳合約",
      theme: "dark",
      config: {
        toolbar: {
          toolbarViewerRight: {
            openFile: false,
            print: false,
            viewBookmark: false,
          },
        },
        sidebar: {
          viewOutline: false,
          viewAttachments: false,
        },
      },
      personData: {},
      contractList: [],
      domainObject,
    };
  },
  components: {
    //VuePdfApp,
    ContractViewAdd,
  },
  computed: {
    nowPlace() {
      return store.nowIndex;
    },
  },
  methods: {
    downloadPdf(item) {
      // PDF文件的URL
      const pdfUrl = item;
      console.log(pdfUrl);
      window.open(pdfUrl, '_blank');
    },

    addNewContract() {
      this.$bvModal.show("addNewContractFile");
    },
    backToLastPage() {
      if (+this.nowPlace == 2 || +this.nowPlace == 4) {
        this.$router.push("ruralnursingdatamainten");
      }  
      else if (+this.nowPlace == 41) {
        this.$router.push("systemmanagetab4");     
      }
      else if (+this.nowPlace == 51) {
        this.$router.push("systemmanagetab5");   
      }  
      else if (+this.nowPlace == 21) {
        this.$router.push("systemmanagetab2");     
      }
      else if (+this.nowPlace == 31) {
        this.$router.push("systemmanagetab3");
      }
      else {
        this.$router.push("systemmanagetab3");
      }
    },
    showContractList() {
      this.getContractList();
      this.$bvModal.show("modalContract");
    },
    async deleteContract(item) {
      const id = item.seq;
      const url = `StudentInfo/DeleteContract?seqno=${id}`;

      try {
        const res = await window.axios.delete(url);
        this.$bvToast.toast(`刪除成功`, {
          title: "合約管理",
          autoHideDelay: 5000,
          variant: "success",
          toaster: "b-toaster-bottom-full",
        });
        this.$bvModal.hide("modalContract");
      } catch (e) {
        this.$bvToast.toast(`刪除失敗` + e, {
          title: "合約管理",
          autoHideDelay: 5000,
          variant: "danger",
          toaster: "b-toaster-bottom-full",
        });
      }
    },
    async getContractList() {
      const id = store.editItem.id;
      let url = "";
      if (this.nowPlace == 21 || this.nowPlace == 31 || this.nowPlace == 2 || this.nowPlace == 41 || this.nowPlace == 51) {
        url = `StudentInfo/GetContractList?identifier=${id}`;
      } else if (this.nowPlace == 3 || this.nowPlace == 4) {
        url = `StudentInfo/GetRepaymentDoc?identifier=${id}`;
      }

      try {
        const res = await window.axios.get(url);
        this.contractList = res.map((s) => ({
          title: s.Remark,
          url: `${this.domainObject.platformOriginal}${s.File_Url}`,
          seq: s.Seqno,
          creat: s.CreateTime,
          size: s.FileSize,
          id: s.Identifier,
        }));
      } catch (e) {
        this.$bvToast.toast(e + ``, {
          title: "系統資訊",
          autoHideDelay: 5000,
          variant: "danger",
        });
      }
    },
    changeContract(item) {
      this.pdfTitle = item.title;
      this.pdfPath = item.url;
      this.$bvModal.hide("modalContract");
    },
  },
  async mounted() {
    this.personData = { ...store.editItem };
    await this.getContractList();
    if (this.contractList.length) {
      this.pdfPath = this.contractList[0].url;
      this.pdfTitle = this.contractList[0].title;
      window.open(this.pdfPath, '_blank');
    }
  },
};
</script>

<style lang="scss" scoped>
$footer-height: 20px;
.footer {
  background: var(--pdf-button-toggled-color);
  color: var(--pdf-toolbar-font-color);
  height: $footer-height;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: initial;
  font-size: 14px;
}
.footer button {
  float: right;
  margin-right: 5px;
}
.theme-toggle::before {
  content: "\e832";
}
::v-deep.pdf-app #thumbnailView {
  width: calc(100% - 20px) !important;
}
::v-deep #outerContainer {
  height: calc(100% - #{$footer-height}) !important;
}
.title {
  background: #24364e;
  margin-bottom: 2px;
  color: #fff;
  padding: 5px 0;
  .info {
    display: inline-block;
  }
}
::v-deep.modal-dialog {
  margin: 8rem auto;
}
::v-deep .modal-header {
  background: #7e2ca3;
  color: white;
}
::v-deep .modal-content {
  width: 120%;
}

.contract {
  cursor: pointer;
}
.contract:hover {
  background: wheat;
  color: #24364e;
}
.dtc-grid-contract {
  margin-top: 5px;
  display: grid;
  grid-template-columns: 470px 80px;
  // grid-gap: 5px;
  .delete {
    margin-top: 6px;
  }
}
</style>
